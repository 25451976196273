import {format} from 'date-fns'

const ReminderDescription = ({every, timeUnit, lastTrigger, task}) => {

    let dateFormat = 'dd-MM-yyyy';
    if (timeUnit === 'minutes' || timeUnit === 'hours') {
        dateFormat = 'HH:mm'
    }
    return (
        <>
            {task && <><span>{task}</span><br /></>}
            <span>Every {every} {timeUnit}</span>
            <br />
            <span> Last time : {lastTrigger ? format(lastTrigger, dateFormat) : "Not defined"}</span>
        </>
    )
}

export default ReminderDescription;