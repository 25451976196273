import {initializeApp} from "firebase/app";
import {getAuth} from "firebase/auth";
import {getFirestore, doc, setDoc, collection, arrayUnion} from "firebase/firestore";
import {getMessaging, getToken, onMessage} from "firebase/messaging";

const firebaseConfig = {
    apiKey: "AIzaSyAUGZncC8_lewmsnajb3gzbMZwwhvpEwE8",
    authDomain: "reminder-394cc.firebaseapp.com",
    projectId: "reminder-394cc",
    storageBucket: "reminder-394cc.appspot.com",
    messagingSenderId: "250252680369",
    appId: "1:250252680369:web:3f6030df780f27ba361690",
    measurementId: "G-35DDWXMREV"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

const messaging = getMessaging(app);

export const fetchToken = (setTokenFound) => {
    return getToken(messaging, {vapidKey: 'BFY3nTdXj-0Me8JTGJC976PUlHPNSXJoM4gqgZ-zaLYYd7JR3msJPQ6BC2RkX1PiMWrRBquDUnUpwl7hCMPo5Fw'}).then((currentToken) => {
        if (currentToken) {
            console.log('current token for client: ', currentToken);
            if (auth.currentUser != null) {
                const docRef = doc(db, "user_token", auth.currentUser.uid);
                setDoc(docRef, {
                    token: arrayUnion(currentToken)
                }, {merge: true})
                    .then(docRef => {
                        console.log("Token user pushed");
                        setTokenFound(true);
                    })
                    .catch(error => {
                        console.log(error);
                        setTokenFound(false);
                    });
            }
            auth.onAuthStateChanged((user) => {
                if (user == null) {
                    return;
                }
                const docRef = doc(db, "user_token", user.uid);
                setDoc(docRef, {
                    token: arrayUnion(currentToken)
                }, {merge: true})
                    .then(docRef => {
                        setTokenFound(true);
                        console.log("Token user pushed");
                    })
                    .catch(error => {
                        setTokenFound(false);
                        console.log(error);
                    });
            })

            setTokenFound(true);

            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
        } else {
            console.log('No registration token available. Request permission to generate one.');
            setTokenFound(false);
            // shows on the UI that permission is required
        }
    }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            console.log(payload);
            resolve(payload);
        });
    });
export default app;
