import Countdown from "react-countdown";

const NextIteration = ({every, timeUnit, nextIteration}) => {

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            if (days > 0) {
                return <span>Passed since {days}d {hours}h</span>;
            } else {
                // Render a completed state
                return <span>Passed since {hours}h {minutes}m {seconds}s</span>;
            }
        } else {
            // Render a countdown
            if (timeUnit === "minutes") {
                if (every < 60) {
                    return <span>Time until next : {hours}h {minutes}m {seconds}s</span>
                } else {
                    return <span>Time until next : {minutes}m {seconds}s</span>
                }
            }  else if (timeUnit === "hours") {
                if (every < 24) {
                    return <span>Time until next : {hours}h {minutes}m</span>
                } else {
                    return <span>Time until next : {days}d {hours}h</span>
                }
            } else if (timeUnit === "days") {
                if (days > 0) {
                    return <span>Time until next : {days}d {hours}h</span>
                } else {
                    return <span>Time until next : {hours}h</span>
                }
            } else if (timeUnit === "weeks") {
                if (days > 0) {
                    return <span>Time until next : {days}d {hours}h</span>
                } else {
                    return <span>Time until next : {hours}h</span>
                }
            } else {
                return <span>Time until next : {days}d</span>
            }
        }
    };

    return (
        <>
            <Countdown
                overtime={true}
                date={nextIteration}
                renderer={renderer}
            />
        </>
    )
}

export default NextIteration;