import React, {useEffect, useState} from 'react';
import {db} from '../firebase-config';
import {collection, query,  getDocs, onSnapshot, where} from "firebase/firestore";
import {useUserAuth} from "../context/UserAuthContext";
import ReminderForm from "./ReminderForm";
import {Col, Row} from "react-bootstrap";
import Reminder from "./Reminder";

const ReminderList = () => {
    const [reminders, setReminders] = useState(JSON.parse(localStorage.getItem("reminder-list")) || []);
    const {user} = useUserAuth();

    const updateReminders = (list) => {
        localStorage.setItem("reminder-list", JSON.stringify(list));
        setReminders(list);
    }
    const fetchReminders = async () => {
        if (user && user.uid) {
            const ref = query(
                collection(db, "reminders"),
                where("owner", "==", user.uid)
            );

            await getDocs(ref)
                .then((querySnapshot) => {
                    const newData = querySnapshot.docs
                        .map((doc) => ({...doc.data(), id: doc.id}));
                    updateReminders(newData);
                }).then(() => {
                    const unsubscribe = onSnapshot(ref, (snapshot) => {
                        if (snapshot.size) {
                            let newReminders = []
                            snapshot.forEach(doc => {
                                let d = {...doc.data()};
                                d["id"] = doc.id;
                                newReminders.push(d);
                            })
                            updateReminders(newReminders);
                        } else {
                            updateReminders([]);
                        }
                    });
                })
        }

    }

    useEffect(() => {
        fetchReminders().then(() => console.log("Loaded"));
    }, [user])

    return (
        <>
            <Col>
                <ReminderForm/>
            </Col>
            <Col lg="9">
                <Row>
               {reminders && reminders.sort((r1, r2) => r1.nextIteration - r2.nextIteration).map((reminder) => (
                    <Reminder reminder={reminder} key={reminder.id} />
                ))}
                </Row>
            </Col>
        </>
    )
}

export default ReminderList