import {useNavigate} from "react-router";
import {useUserAuth} from "../context/UserAuthContext";
import {Button, Container, Row} from "react-bootstrap";
import ReminderList from "./ReminderList";
import NavigationBar from "./NavigationBar";

const Home = () => {
    const {logOut, user} = useUserAuth();
    const navigate = useNavigate();
    const handleLogout = async () => {
        try {
            await logOut();
            navigate("/");
        } catch (error) {
            console.log(error.message);
        }
    };

    return (
        <>
            <NavigationBar />
            <Container>
                <Row>
                    <ReminderList/>
                </Row>
            </Container>
        </>
    );
};

export default Home;