import React, {useState} from 'react';

import {db} from '../firebase-config';
import {addDoc, collection, doc, getDoc, setDoc} from "firebase/firestore";
import {useUserAuth} from "../context/UserAuthContext";
import {Button, Card, Form, Toast, ToastContainer} from "react-bootstrap";
import {Save, SignStop} from "react-bootstrap-icons";
import {CardBody, CardFooter, CardTitle, Row} from "reactstrap";


const ReminderForm = ({reminder, save}) => {
    const [name, setName] = useState(reminder ? reminder.name : "New reminder")
    const [task, setTask] = useState(reminder ? reminder.task : "Task")
    const [every, setEvery] = useState(reminder ? reminder.every : 10)
    const [timeUnit, setTimeUnit] = useState(reminder ? reminder.timeUnit : "minutes")
    const [showError, setShowError] = useState(false);
    const toggleShowError = () => setShowError(false);

    const {user} = useUserAuth();

    const handleChangeName = (e) => {
        e.preventDefault(); // prevent the default action
        setName(e.target.value); // set name to e.target.value (event)
    };

    const handleChangeEvery = (e) => {
        e.preventDefault(); // prevent the default action
        setEvery(e.target.value); // set name to e.target.value (event)
    };

    const handleChangeTimeUnit = (e) => {
        e.preventDefault();
        setTimeUnit(e.target.value);
    }

    const handleChangeTask = (e) => {
        e.preventDefault();
        setTask(e.target.value);
    }

    const addReminder = async (e) => {

        if (!name || !every || !timeUnit) {
            setShowError(true);
            return;
        }
        try {
            let now = Date.now();
            const docRef = await addDoc(collection(db, "reminders"), {
                name: name,
                every: every,
                timeUnit: timeUnit,
                lastTrigger: now,
                nextIteration: getNextIteration(now),
                createdAt : now,
                history: [now],
                task: task,
                owner : user.uid
            });
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    function getDaysInCurrentMonth() {
        const date = new Date();

        return new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        ).getDate();
    }

    function daysInYear(year) {
        return ((year % 4 === 0 && year % 100 > 0) || year %400 === 0) ? 366 : 365;
    }

    function getNextIteration(lastTrigger) {
        let unit = 1;
        if (timeUnit === "minutes") {
            unit *= 1000 * 60;
        } else if (timeUnit === "hours") {
            unit *= 1000 * 60 * 60;
        } else if (timeUnit === "days") {
            unit *= 1000 * 60 * 60 * 24;
        } else if (timeUnit === "weeks") {
            unit *= 1000 * 60 * 60 * 24 * 7;
        } else if (timeUnit === "months") {
            unit *= 1000 * 60 * 60 * 24 * getDaysInCurrentMonth();
        } else if (timeUnit === "years") {
            unit *= 1000 * 60 * 60 * 24 * daysInYear(new Date().getFullYear());
        }
        const durationBetweenTooTrigger = every * unit;
        return lastTrigger + durationBetweenTooTrigger;
    }

    const updateReminder = async (e) => {

        try {
            const docRef = doc(db, "reminders", reminder.id);
            let document = await getDoc(docRef);
            let lastTrigger = document.data().lastTrigger;
            setDoc(docRef, {
                name: name,
                every: every,
                timeUnit: timeUnit,
                nextIteration: getNextIteration(lastTrigger),
                task: task,
                owner : user.uid
            }, {merge: true});
            console.log("Document written with ID: ", docRef.id);
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    }

    const cancel = (e) => {
        e.preventDefault();
        if (save) {
            save();
        }
    }

    const submit = async (e) => {
        e.preventDefault();

        if (!name || !every || !timeUnit) {
            setShowError(true);
            return;
        }
        if (reminder) {
            updateReminder();
        } else {
            addReminder();
        }

        if (save) {
            save();
        }
    }

    return (
        <>
            <Card className="card-stats">
                <CardBody>
                    <Row>
                            <CardTitle className="text-uppercase text-muted mb-0">
                                {reminder ? "Update reminder" : "Add new reminder"}
                            </CardTitle>

                        <Form>
                            <Form.Group className="mb-3" controlId="formName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control value={name} type="text" placeholder="Enter name" onChange={handleChangeName}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="forTask">
                                <Form.Label>Task</Form.Label>
                                <Form.Control value={task} type="text" placeholder="Enter task" onChange={handleChangeTask}/>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="forEvery">
                                <Form.Label>Every</Form.Label>
                                <Form.Control value={every} type="number" placeholder="Enter every" onChange={handleChangeEvery}/>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="forTimeUnit">
                                <Form.Select
                                    value={timeUnit}
                                    onChange={handleChangeTimeUnit}
                                >
                                    <option value="minutes">Minutes</option>
                                    <option value="hours">Hours</option>
                                    <option value="days">Days</option>
                                    <option value="weeks">Weeks</option>
                                    <option value="months">Months</option>
                                    <option value="years">Years</option>
                                </Form.Select>
                            </Form.Group>
                        </Form>
                    </Row>
                </CardBody>
                <CardFooter>
                    <Button className="me-2" variant="primary" type="submit" onClick={submit}>
                        <Save />
                    </Button>
                    {reminder &&
                        <Button variant="warning" type="submit" onClick={cancel}>
                            <SignStop />
                        </Button>
                    }
                </CardFooter>
            </Card>
            <ToastContainer className="p-3" position="top-end">
                <Toast show={showError} onClose={toggleShowError}>
                    <Toast.Header>
                        <strong className="me-auto">Error</strong>
                    </Toast.Header>
                    <Toast.Body>Please fill the form before creating a new reminder.</Toast.Body>
                </Toast>
            </ToastContainer>
        </>
    )
}

export default ReminderForm