import React, {useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Form, Alert} from "react-bootstrap";
import {Button} from "react-bootstrap";
import {useUserAuth} from "../context/UserAuthContext";
import {Col, Container, Row} from "reactstrap";

const Signup = () => {
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [password, setPassword] = useState("");
    const {signUp} = useUserAuth();
    let navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError("");
        try {
            await signUp(email, password);
            navigate("/");
        } catch (err) {
            setError(err.message);
        }
    };

    return (
        <>
            <Container>
                <Row className="justify-content-md-center">
                    <Col lg={4}>
                        <div className="p-4 box">
                            <h2 className="mb-3">Reminder App Signup</h2>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Form onSubmit={handleSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        type="email"
                                        placeholder="Email address"
                                        onChange={(e) => setEmail(e.target.value)}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        type="password"
                                        placeholder="Password"
                                        onChange={(e) => setPassword(e.target.value)}
                                    />
                                </Form.Group>

                                <div className="d-grid gap-2">
                                    <Button variant="primary" type="Submit">
                                        Sign up
                                    </Button>
                                </div>
                            </Form>
                        </div>
                        <div className="p-4 box mt-3 text-center">
                            Already have an account? <Link to="/">Log In</Link>
                        </div>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default Signup;