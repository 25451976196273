import {Button, Card, Col} from "react-bootstrap";
import NextIteration from "./NextIteration";
import ReminderDescription from "./ReminderDescription";
import React, {useEffect, useState} from "react";
import {db} from "../firebase-config";
import {doc, setDoc, arrayUnion} from "firebase/firestore";
import {deleteDoc} from "@firebase/firestore";
import {ArrowClockwise, Bell, PencilSquare, Trash} from "react-bootstrap-icons";
import ReminderForm from "./ReminderForm";
import {CardBody, CardFooter, CardTitle, Row} from "reactstrap";

const Reminder = ({reminder}) => {

    const [isEditMode, setIsEditMode] = useState(false);
    const [bgColor, setBgColor] = useState("");

    function getDaysInCurrentMonth() {
        const date = new Date();

        return new Date(
            date.getFullYear(),
            date.getMonth() + 1,
            0
        ).getDate();
    }

    function daysInYear(year) {
        return ((year % 4 === 0 && year % 100 > 0) || year %400 === 0) ? 366 : 365;
    }

    function getNextIteration(lastTrigger) {
        let unit = 1;
        let timeUnit = reminder.timeUnit;
        if (timeUnit === "minutes") {
            unit *= 1000 * 60;
        } else if (timeUnit === "hours") {
            unit *= 1000 * 60 * 60;
        } else if (timeUnit === "days") {
            unit *= 1000 * 60 * 60 * 24;
        } else if (timeUnit === "weeks") {
            unit *= 1000 * 60 * 60 * 24 * 7;
        } else if (timeUnit === "months") {
            unit *= 1000 * 60 * 60 * 24 * getDaysInCurrentMonth();
        } else if (timeUnit === "years") {
            unit *= 1000 * 60 * 60 * 24 * daysInYear(new Date().getFullYear());
        }
        const durationBetweenTooTrigger = reminder.every * unit;
        return lastTrigger + durationBetweenTooTrigger;
    }

    const resetTimer = async () => {
        const docRef = doc(db, "reminders", reminder.id);
        let now = Date.now();
        setDoc(docRef, {
            lastTrigger: now,
            nextIteration: getNextIteration(now),
            history: arrayUnion(now)
        }, {merge: true})
            .then(docRef => {
                console.log("Document Field has been updated successfully");
            })
            .catch(error => {
                console.log(error);
            })
    }

    const deleteReminder = async () => {
        const docRef = doc(db, "reminders", reminder.id);

        deleteDoc(docRef)
            .then(() => {
                console.log("Document has been deleted successfully");
            })
            .catch(error => {
                console.log(error);
            })
    }

    const editTimer = () => {
        setIsEditMode(current => true);
    }

    const updateColor = () => {
        let color = "bg-green";
        let between2Iterations = reminder.nextIteration - reminder.lastTrigger;
        let betweenNowAndNextIteration = reminder.nextIteration - Date.now();
        if (betweenNowAndNextIteration < 0) {
            color = "bg-red";
        } else if (betweenNowAndNextIteration / between2Iterations < .25) {
            color = "bg-orange";
        } else if (betweenNowAndNextIteration / between2Iterations < .50) {
            color = "bg-yellow";
        }
        setBgColor(color);
    }
    useEffect(() => {
        updateColor();
        const interval = setInterval(() => {
            updateColor();
        }, 1000);
        return () => clearInterval(interval);
    }, [reminder]);


    return (
        <>
            <Col lg="6">
                {!isEditMode &&
                <Card className="card-stats">
                    <CardBody>
                        <Row>
                            <div className="col">
                                <CardTitle className="text-uppercase text-muted mb-0">
                                    {reminder.name}
                                </CardTitle>
                                <span className="h2 font-weight-bold mb-0"><NextIteration every={reminder.every} nextIteration={reminder.nextIteration}
                                                                                          timeUnit={reminder.timeUnit}/></span>
                            </div>
                            <Col className="col-auto">
                                <div className={`icon icon-shape text-white rounded-circle shadow ${bgColor}`}>
                                    <Bell />
                                </div>
                            </Col>
                        </Row>
                        <p className="mt-3 mb-0 text-sm">
                            <span className="text-nowrap"> <ReminderDescription every={reminder.every} lastTrigger={reminder.lastTrigger}
                                                                                timeUnit={reminder.timeUnit} task={reminder.task}/></span>
                        </p>
                    </CardBody>
                    <CardFooter>
                        <Button className="me-2" variant="primary" onClick={resetTimer}>
                            <ArrowClockwise/>
                        </Button>
                        <Button className="me-2" variant="info" onClick={editTimer}>
                            <PencilSquare/>
                        </Button>
                        <Button variant="warning" onClick={deleteReminder}>
                            <Trash/>
                        </Button>
                    </CardFooter>
                </Card>
                }
                {isEditMode && <ReminderForm reminder={reminder} save={setIsEditMode}/>
                }
            </Col>
        </>
    )
}

export default Reminder;