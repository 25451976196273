import { createContext, useContext, useEffect, useState } from "react";
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    onAuthStateChanged,
    signOut,
    GoogleAuthProvider,
    signInWithPopup,
    setPersistence,
    browserLocalPersistence
} from "firebase/auth";

import { auth } from "../firebase-config";
import {useNavigate} from "react-router-dom";

const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
    const navigate = useNavigate();
    const [user, setUser] = useState({});
    function logIn(email, password, persist) {
        if (persist) {
            return setPersistence(auth, browserLocalPersistence).then(() => signInWithEmailAndPassword(auth, email, password));
        } else {
            return signInWithEmailAndPassword(auth, email, password)
        }
    }
    function signUp(email, password) {
        return createUserWithEmailAndPassword(auth, email, password);
    }
    function logOut() {
        return signOut(auth);
    }
    function googleSignIn() {
        const googleAuthProvider = new GoogleAuthProvider();
        return signInWithPopup(auth, googleAuthProvider);
    }

    useEffect(() => {

        if (auth.currentUser != null) {
            setUser(auth.currentUser);
            navigate("/home");
        }

        const unsubscribe = onAuthStateChanged(auth, (currentuser) => {
            console.log("Auth", currentuser);
            setUser(currentuser);
        });

        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <userAuthContext.Provider
            value={{ user, logIn, signUp, logOut, googleSignIn }}
        >
            {children}
        </userAuthContext.Provider>
    );
}

export function useUserAuth() {
    return useContext(userAuthContext);
}