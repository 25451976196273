import {Container, Row, Col, Toast, Button} from "react-bootstrap";
import {Routes, Route} from "react-router-dom";
import "./App.css";
import Home from "./components/Home";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ProtectedRoute from "./components/ProtectedRoute";
import {UserAuthContextProvider} from "./context/UserAuthContext";
import {useState} from "react";
import {fetchToken, onMessageListener} from "./firebase-config";

import logo from './logo.svg';
function App() {

    const [show, setShow] = useState(false);
    const [notification, setNotification] = useState({title: '', body: ''});
    const [isTokenFound, setTokenFound] = useState(false);
    fetchToken(setTokenFound);

    onMessageListener().then(payload => {
        setNotification({title: payload.notification.title, body: payload.notification.body})
        setShow(true);
        console.log(payload);
    }).catch(err => console.log('failed: ', err));

    return (
        <>

            <Container>
                <Row>
                    <Col>
                        <UserAuthContextProvider>
                            <Routes>
                                <Route
                                    path="/home"
                                    element={
                                        <ProtectedRoute>
                                            <Home/>
                                        </ProtectedRoute>
                                    }
                                />
                                <Route path="/" element={<Login/>}/>
                                <Route path="/signup" element={<Signup/>}/>
                            </Routes>
                        </UserAuthContextProvider>
                    </Col>
                </Row>
                <Toast onClose={() => setShow(false)} bg="light" show={show} animation style={{
                    position: 'absolute',
                    top: 20,
                    right: 20,
                    minWidth: 200
                }}>
                    <Toast.Header>
                        <img src="reminder.png" className="rounded" style={{width: "20px", height: "20px"}} alt="" />
                        <strong className="mr-auto">{notification.title}</strong>
                    </Toast.Header>
                    <Toast.Body><b>It is time!!!</b><br />You can now do this task : <br />{notification.body}<br /> And remember to reset the timer for next time!</Toast.Body>
                </Toast>
            </Container>
        </>
    );
}

export default App;